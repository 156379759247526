<!--
 * @Author: your name
 * @Date: 2021-09-15 16:45:05
 * @LastEditTime: 2021-09-26 10:29:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \zhfd-web-sale\src\views\FundSchool\carousel.vue
-->
<template>
  <div>
    <div v-if="imgData.length" class="carousel">
      <swiper ref="mySwiper" class="swiper swiper-wrapper" :options="swiperOption">
        <swiper-slide v-for="(item,index) in imgData" :key="item.imgId">
          <router-link :to="`/model?schoolType=${index}&mode=${item.imgName}`" class="btn">
            <img :src="item.imgUrl" />
          </router-link>
        </swiper-slide>
        <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
        <div slot="button-next" class="swiper-button-next swiper-button-white" />
      </swiper>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  components: {
    swiper,
    swiperSlide
  },
  props: {
    imgData: {
      type: Array
    }
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 'auto',
        spaceBetween: 30
      }
    }
  }
}
</script>

<style scoped>
.carousel {
  height: 438px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.swiper {
  border-radius: 10px;
  height: 330px;
}
.swiper-slide {
  width: 780px;
  height: 330px;
  border-radius: 10px;
  display: block;
  overflow: hidden;
}
.swiper-button-prev {
  width: 68px;
  height: 68px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
}
.swiper-button-next {
  width: 68px;
  height: 68px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: 0;
}
</style><style scoped>
.carousel {
  height: 438px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.swiper {
  border-radius: 10px;
  height: 330px;
}
.swiper-slide {
  width: 780px;
  height: 330px;
  border-radius: 10px;
  display: block;
  overflow: hidden;
}
.swiper-button-prev {
  width: 68px;
  height: 68px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
}
.swiper-button-next {
  width: 68px;
  height: 68px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: 0;
}
</style>