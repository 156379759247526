<template>
  <!-- 基金学堂 -->
  <div class="bg">
    <div class="container">
      <div class="banner">
        <span>
          当前位置：
          <router-link to="/" class="bread">众惠首页</router-link>&gt;
        </span>
        <span style="color: #6b6b6b">基金学堂</span>
      </div>
      <div class="middle">
        <div class="list">
          <router-link to="/information" class="btn">热门资讯</router-link>
          <div class="active btn">基金学堂</div>
          <router-link to="/activeList" class="btn">活动列表</router-link>
        </div>
        <div class="content-top">
          <div to="/detail" class="swiper1">
            <!-- 轮播图开始 -->
            <Carousel :img-data="imgData" />
            <!-- 轮播图结束 -->
          </div>
        </div>
        <h3>近期学堂</h3>
        <div class="content">
          <div v-for="(item, index) in mydata" :key="index" class="box">
            <div class="box-content">
              <div class="box-left">
                <router-link :to="'/detail?infoId='+item.infoId">
                  <img :src="item.webCoverUrl" alt />
                </router-link>
                <div>
                  <router-link :to="'/detail?infoId='+item.infoId" class="box-p">{{ item.title }}</router-link>
                  <div class="box-h2">{{ item.infoSource }}</div>
                  <div class="box-h4">来源</div>
                </div>
              </div>
              <div class="box-right">
                <div class="hot">
                  <span class="iconfont">&#xe680;</span>
                  <span>{{ item.readCount }}</span>
                </div>
              </div>
            </div>
          </div>
          <pagination
            v-if="total > 0"
            :total="total"
            :page.sync="pageNum"
            :limit.sync="pageSize"
            :layout="layout"
            @pagination="getList"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.bg {
  background-color: #f3f4f6;
  margin-bottom: -30px;
}
.container {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
}
.banner {
  height: 16px;
  width: 248px;
  text-align: left;
  color: #a5a5a5;
  font-size: 14px;
  padding-top: 24px;
  padding-bottom: 24px;
  line-height: 16px;
}
.bread {
  color: #a5a5a5;
}
.middle {
  height: 100%;
  background-color: #fff;
  margin: 0 auto;
  padding: 40px;
}
.list {
  display: flex;
  margin-bottom: 40px;
}
.btn {
  height: 50px;
  width: 124px;
  border: 1px solid #fff;
  font-size: 18px;
  color: #a5a5a5;
  text-align: center;
  line-height: 50px;
  margin-right: 24px;
}
.active {
  border: 1px solid #ce9b63;
  border-radius: 26px;
  color: #ce9b63 !important;
  font-weight: 700;
  font-size: 18px;
}
.content-top {
  display: flex;
  align-items: center;
  height: 370px;
  box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);
}
.swiper1 {
  height: 330px;
  width: 100%;
  overflow: hidden;
  margin: 0 20px;
  border-radius: 10px;
  display: block;
}
.middle h3 {
  margin: 24px 0;
  font-size: 20px;
  font-weight: 700;
}
.box {
  height: 156px;
  width: 100%;
  margin-right: 40px;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 1px 0px 0px #edf1f7;
}
.box-content {
  height: 116px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
}
.box-left {
  display: flex;
}
.box-left img {
  width: 174px;
  height: 116px;
  border-radius: 7px;
  margin-right: 24px;
}
.box-p {
  margin-top: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #313237;
  display: block;
}
.box-h2 {
  font-size: 9px;
  font-weight: 400;
  color: #313237;
  margin-top: 49px;
  display: block;
}
.box-h4 {
  color: #8691a8;
  font-size: 9px;
  font-weight: 400;
  display: block;
}
.box-right {
  line-height: 220px;
  margin-right: 15px;
  margin-bottom: 21px;
}
.hot {
  display: flex;
  align-items: center;
}
.box-right span {
  font-size: 9px;
  color: #8691a8;
  font-weight: 400;
}
</style>

<script>
import { fundSchool, getImgPage } from '@/api/activeList'
import { topList } from '@/api/news.js'
import Carousel from './carousel.vue'
export default {
  components: { Carousel },
  data() {
    return {
      mydata: [],
      layout: 'prev, pager, next,sizes',
      total: 10,
      pageNum: 1,
      pageSize: 10,
      imgData: [],
      eduNews: []
    }
  },
  mounted() {
    this.getList()
    this.getImg()
    this.topList()
  },
  methods: {
    topList() {
      topList('1').then((result) => {
        if (!result.data) {
          return
        }
        this.eduNews = result.data
      })
    },
    /**
     * @description: 查询学堂列表
     * @param {*}
     * @return {*}
     */
    getList() {
      fundSchool({
        infoType: 1,
        publishStatus: '1',
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then((res) => {
        if (res.code === 200) {
          console.log(res.data.data)
          this.mydata = res.data.data
          this.total = res.data.total
        }
      })
    },
    /**
     * @description: 查询轮播图
     * @param {*}
     * @return {*}
     */
    getImg() {
      let obj = {
        // 图像使用位置 0:首页 1:我的 2:启动页 3 :了解众惠 4:首次下载引导页 10:基金学堂用
        imgUseSite: '10',
        // 图像使用平台 0:投资者APP端 1:投资者WEB端 2:销售APP端
        imgUsePlatform: '1',
        pageNum: 1,
        pageSize: 3
      }
      getImgPage(obj).then((res) => {
        if (res.code === 200) {
          this.imgData = res.data.data
        }
      })
    }
  }
}
</script>
